<template>
  <div class="list">
    <div class="game-select">
      <!-- 头部 -->
      <div class="list_item search">
        <div class="search_tabs">
          <span :class="action.gameTypeId===''?'active':''" @click="getAllGameData">
            全部游戏
          </span>
          <span v-for="(item,index) in gameClass" :key="index" :class="action.gameTypeId === item.id?'active':''"
                @click="gameClassClick(item)">
              {{ item.class_name }}
          </span>
        </div>
        <div class="search_input">
          <input type="text" v-model="search_input_text" @keyup.enter="searchBtnClick" placeholder="请输入关键词进行搜索">
          <div class="search_btn" @click="searchBtnClick">
            <img src="@/assets/image/Footer/search.png">
          </div>
        </div>
      </div>
      <!-- 字母筛选 -->
      <div class="list_item opiton">
        <div>
          <span :class="action.firstChar===item?'active_A-Z':''" v-for="item in alphabet" :key="item" v-text="item"
                @click="onClickAlphabet(item)"></span>
        </div>
      </div>
      <!-- 选择游戏 -->
      <div class="list_item games">
        <ul>
          <li v-for="item in gameList" :key="item.game_id" @click="gamesClick(item)">
            <div class="game_img"><img :src="item.game_image" alt=""></div>
            <div class="game_name">{{ item.game_name }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="select-tip">
      <img src="@/assets/image/Footer/arrow.png">
      选择游戏后自动跳转账号列表
    </div>
  </div>
</template>
<script>
import {apiGameList, apiGameClass} from "@/request/API";
import storage from "@/util/storage";

export default {
  name: 'allgame',
  props: {
    //游戏点击函数
    gameClick: {
      type: Function,
      default: null
    },
    product_id: {
      type: [String,Number],
      default: ''
    },
    other: {
      type: Boolean,
      default: false,
    },
    accRecycle: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      //游戏类型 && 首字母
      action: {
        gameTypeId: '',
        firstChar: "全部",
      },
      //A-Z
      alphabet: [],
      //搜索框文本
      search_input_text: '',
      //游戏列表
      gameList: [
        {game_name: '', game_image: '', game_class: '', game_id: ''},
      ],
      gameClass: [],
      //最近浏览
      historyGameList: [],
      //清空最近浏览Hover
      clear: false,
      // 站内信
      stationLetter: {},
      //无板块游戏
      otherGame:'',
      gameListApi:apiGameList
    }
  },
  methods: {

    //首字母列表点击事件
    onClickAlphabet(item) {
      this.action.firstChar = item
      if (item === '全部') {
        item = ''
      }
      this.getGameList('', item, this.action.gameTypeId)
    },
    // 展开全部游戏 获取全部游戏列表
    getAllGameData() {
      this.action = {
        gameTypeId: '',
        firstChar: "全部",
      }
      this.getGameList()
    },
    // 游戏类型点击事件
    gameClassClick(data) {
      this.action = {
        gameTypeId: data.id,
        firstChar: "全部",
      }
      this.getGameList('', '', data.id)
    },
    //搜索按钮点击事件
    searchBtnClick() {
      this.action = {
        gameTypeId: '',
        firstChar: "全部",
      }
      this.getGameList(this.search_input_text, '', '')
    },
    /**
     * 获取全部游戏列表
     * @param match 搜索框
     * @param game_letter 首字母
     * @param game_class 游戏类别
     */
    getGameList(match, game_letter, game_class) {
      if (game_class === '网络游戏') {
        game_class = '1'
      } else if (game_class === '手机游戏') {
        game_class = '2'
      }
      this.gameListApi({
        match: match,
        game_letter: game_letter,
        class_id: game_class,
      }).then(res => {

        this.gameList = res.data
        if (this.other) {
          let otherGame = {}
          for (let datumKey in res.data[res.data.findIndex(v => v.game_id === 13)]) {
            otherGame[datumKey] = res.data[res.data.findIndex(v => v.game_id === 13)][datumKey]
          }
          otherGame.game_id= 0
          otherGame.game_name= '无板块游戏'
          otherGame.game_image= 'https://images.pxb7.com/images/upload/image/20220316/1647413254342569.png'
          otherGame.sell_customer= res.data[res.data.findIndex(v => v.game_id === 13)].sell_customer
          this.gameList.unshift(otherGame)
        }
      })
    },
    /**
     *
     */
    getGameClass() {
      apiGameClass({}).then(res => {
        this.gameClass = res.data
      })
    },
    /**
     * 点击游戏
     * @param item 游戏
     */
    gamesClick(item,next,sLetter) {
      if (item.game_id === 0){
        this.gameClick(item,next,sLetter)
        return
      }
      let ary = this.historyGameList
      if (storage.getItem('historyGameListXH')) {
        ary = JSON.parse(storage.getItem('historyGameListXH'))
        if (ary.findIndex(items => items.game_id === item.game_id) === -1) {
          ary.unshift(item)
        } else {
          ary.splice(ary.findIndex(items => items.game_id === item.game_id), 1)
          ary.unshift(item)
        }
        ary = ary.slice(0, 10)
        ary = ary.slice(0, 10)
      } else {
        ary = []
        ary.unshift(item)
      }
      storage.setItem('historyGameListXH', JSON.stringify(ary))
      this.historyGameList = ary
      this.gameClick(item,next,sLetter)
    },

    /**
     * 清空最近浏览
     */
    clearHistory() {
      this.historyGameList = []
      storage.setItem('historyGameListXH', JSON.stringify(this.historyGameList))
    },

    /**
     * 获取最近浏览游戏列表
     */
    getHistoryList() {
      this.historyGameList = JSON.parse(storage.getItem('historyGameListXH'))
    }
  },
  mounted() {
    //获取首字母列表
    for (var i = 0; i < 26; i++) {
      this.alphabet.push(String.fromCharCode(65 + i))
    }
    this.alphabet.unshift('全部')

    if (this.accRecycle) {
      this.gameListApi = apiRecyclingGameList
    }
    //默认获取全部游戏列表
    this.getAllGameData()
    this.getGameClass()
    this.getHistoryList()
  },
  watch: {},
  computed: {}
}
</script>

<style lang='less' scoped>
.list {
  width: 66.56vw;
  box-sizing: border-box;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px 40px;
  position: absolute;
  right: 0;
  height: 100%;
  .game-select{
    flex: 1;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.20);
    // .list_item {
    //   width: 1160px;
    // }

    .search {
      height: 85px;
      box-sizing: border-box;
      background: linear-gradient(180deg,#4459a4, #5870c4 100%);
      display: flex;
      // align-items: center;
      justify-content: space-between;
      padding: 20px 44px 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .search_tabs {
        //width: 236px;
        align-self: flex-end;
        display: flex;
        justify-content: space-between;

        span {
          // margin: 0 30px 0 0;
          width: 120px;
          height: 44px;
          line-height: 52px;
          text-align: center;
          font-size: 20px;
          color: #fff;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 500;
          padding-bottom: 4px;
          cursor: pointer;
        }

        .active {
          background: #fff;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          color: #283748;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 600;
          position: relative; //给当前元素添加相对定位,也就是after的父级
          &::before {
            content: " ";
            width: 32px;
            height: 7px;
            background: #5B77E1;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      .search_input {
        position: relative;

        input {
          width: 18.23vw;
          // width: 260px;
          height: 46px;
          background: #FFFFFF;
          border-radius: 10px;
          border: 2px solid #283748;
          padding: 0 62px 0 28px;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #5B5B5B;
        }

        input::-webkit-input-placeholder {
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #E2E2E2;
        }

        .search_btn {
          width: 41px;
          height: 41px;
          position: absolute;
          top: 13px;
          // left: 305px;
          right: 11px;
          img {
            width: 24px;
            height: 24px;
            object-fit: cover;
            cursor: pointer;
          }
        }
      }
    }

    .opiton {
      height: 56px;
      background: #F4F4F4;
      border-radius: 40px;
      margin: 22px 20px 0;

      div {
        height: 56px;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        span {
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          // padding: 0 14px;
        }

        span:hover {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #5B77E1;
          cursor: pointer;
        }

        .active_A-Z {
          color: #5B77E1 !important;
        }
      }
    }

    .games, .history {
      margin: 0 20px;

      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        // width: 1180px;
        padding-top: 17px;

        li, a {
          width: 80px;
          margin: 0 20px 22px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          .game_img {
            width: 80px;
            height: 80px;
            border-radius: 20px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              border-radius: 20px;
              object-fit: cover;
              transition: 0.3s ease-in-out;
            }
          }

          .game_name {
            width: 100px;
            margin-top: 15px;
            text-align: center;
            font-size: 16px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #5B5B5B;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        li:hover {
          .game_img {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }

    .history {
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 0px;

      .historyTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .titleText {
          padding: 20px 0;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #000000;
        }

        .clear {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #b4b4b4;
          cursor: pointer;
          display: flex;
          align-items: center;

          img {
            margin: 0 6px 0 0;
          }
        }

        .clear:hover {
          color: #FCAD13;
        }

      }

      ul {
        padding: 0;
      }
    }
  }
  .select-tip{
    margin: 26px 0 0 44px;
    width: 250px;
    height: 37px;
    line-height: 37px;
    background: #e9edff;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #0025ab;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img{
      width: 14px;
      height: 16px;
      margin: 0 8px 0 11px;
      vertical-align: middle;
    }
  }
}
</style>
