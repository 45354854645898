<template>
  <div class="all-game-box">
    <div class="all_game">
      <div class="al-game-left">
        <img :src="$store.state.channelConfig.index_img">
<!--        <img src="@/assets/image/Footer/left.png">-->
      </div>
      <gamelist :game-click="gameClick"></gamelist>
    </div>
  </div>

</template>
<script>
import gamelist from "@/components/gameList/index"

export default {
  name: 'allgame',
  props: {
  },
  components: {
    gamelist
  },
  data () {
    return {

    }
  },
  methods: {
    /**
     * 游戏列表点击数事件
     * @param item 游戏
     */
    gameClick(item){
      console.log(item);
      if (item.game_id == '26') {
        let routeUrl = this.$router.resolve({
          name:'SelectGame',
          params:{
            game_name:item.game_name,
            game_class:item.game_class,
            game_id:item.game_id,
            productTypeSelect:1
          },
          query:{
            game_id:item.game_id,
            game_alias:'yuanshen5',
            s:this.$store.state.selectToken
          }
        })
        window.open(routeUrl.href, '_self')
      }else {
        let routeUrl = this.$router.resolve({
          name:'SelectGame',
          params:{
            game_name:item.game_name,
            game_class:item.game_class,
            game_id:item.game_id,
            productTypeSelect:1
          },
          query:{
            game_id:item.game_id,
            game_alias:'games',
            s:this.$store.state.selectToken
          }
        })
        window.open(routeUrl.href, '_self')
      }
    }
  },
  mounted(){

  },
  watch: {

  },
  computed: {

  }
}
</script>

<style lang='less' scoped>
.all-game-box{
  width: 100vw;
  // height: 1029px;
  box-sizing: border-box;
  padding: 51px 0 66px;
  background: url('../../assets/image/Footer/bg.png') no-repeat;
  background-size: 100%;
  .all_game{
    width: 90vw;
    box-sizing: border-box;
    margin: 0 auto;
    // background: #ffffff;
    border-radius: 20px;
    box-shadow: 13px 12px 20px 0px rgba(0,28,99,0.10);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .al-game-left{
      width: 450px;
      height: 912px;
      //width: 23.4375vw;
      //height: 84.444vh;
      img{
        border-radius: 20px 0 0 20px;
        width: 100%;
        height: 100%;
        object-fit: fill;
        vertical-align: middle;
      }
    }
  }
}

</style>
